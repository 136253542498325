import colors from "../../../constants/Colors";

const styles = {
  modifiedCard: {
    borderStyle: "solid",
    borderColor: colors.silver
  },
  wrapper: {
    backgroundColor: colors.white,
    margin: "5% 0px 0 34px",
    height: "95%",
    padding: "2vh",
    paddingBottom:"8vh"
  },
  firstItemWrapper: {
    backgroundColor: colors.white,
    margin: "5% 0px 0 0px",
    height: "95%",
    padding: "2vh",
    paddingBottom:"8vh"
  },
  photoPlaceholder: {
    width: "100%",
    height:"30%",
    marginBottom: "3%"
  },
  photo: {
    maxWidth:"100%",
    maxHeight:"100%",
    width: "auto",
    height: "auto"
  }
}

export default styles;
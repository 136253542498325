import colors from "../../constants/Colors";

const styles = {
  wrapper: {
    color: colors.dodgerBlue,
    padding: "4% 0",
    letterSpacing: "0.1em",
    textAlign: "end",
  }
}

export default styles;
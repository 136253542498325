export const openSuccessAlert = (action) => {
  window.alert(`Uspješno ste ${action} djelo.`);
}

export const openFailedAlert = (action) => {
  window.alert(`${action} djela nije uspjelo.`);
}

/**
 * This method is a wrapper for certain actions (changing the active collection, going to table view, scrolling forward or backward), 
 * when the user is trying to leave the _Catalog View_. 
 * If changes are made in the current cards, the conformation dialog appears and depending on the users conformation input, 
 * _action_ is called or user remains in _Catalogue View_.
 * If no changes are made in the current cards, _modifedCards_ state is empty and method _action_ is called.
 * @param {*} modifiedCards represents a state as an object whose keys are the indexes of each modified work in the _Catalogue View_ and value is an array of names of modified inputs.
 * @param {*} setModifiedCards setter for _modifiedCards_ state
 * @param {*} action method that is wrapped
 */
export const unsavedChangesDialog = (modifiedCards, setModifiedCards, action) => {
  if(Object.keys(modifiedCards).length !== 0) {
    if(window.confirm("Imate nesačuvane promjene koje će biti izgubljene.")) {
      setModifiedCards({});
      action();
    } else { 
      // stay in the Catalogue View
    }
  } else {
    action();
  }
}
import React, { useState, useEffect } from "react";
import Input from "../input/Input";

const Form = ({ formData, handleChange, autogeneratedCode, isCatalogueForm, index }) => {
  const inputs = [
    {label: "redni broj", name: "ordinal", first: true, value:formData.ordinal},
    {label: "kod", name: "code", first: true, value: autogeneratedCode.editable}, 
    {label:"opisni naslov", name: "descriptiveTitle", value: formData.descriptiveTitle }, 
    {label: "tehnika", name: "technique", value: formData.technique}, 
    {label:"kolekcija", name: "collection", value: formData.collection}, 
    {label: "materijal", name: "material", value: formData.material}, 
    {label: "format", name: "format", value: formData.format}, 
    {label: "total format", name: "totalFormat", value: formData.totalFormat}, 
    {label:"okvir za sliku", name: "frame", value: formData.frame}, 
    {label:"pozadina", name: "back", value: formData.back}, 
    {label:"godina", name: "year", value: formData.year}, 
    {label:"potpis", name: "signature", value: formData.signature}, 
    {label:"nedostatci", name: "flaw", first:true, value: formData.flaw}, 
    {label:"drugi znakovi", name: "otherFlaws", value: formData.otherFlaws}, 
    {label:"broj slike", name: "photoNumber", value: formData.photoNumber}, 
    {label:"publikacija", name: "publication", first:true, value: formData.publication}, 
    {label:"drugo", name: "other", value: formData.other}, 
    {label:"tema", name: "theme", first:true, value: formData.theme }, 
    {label:"stanje", name: "condition", value: formData.condition},
    {label:"tags", name: "tags", value: formData.tags}
  ]
  const [ordinal, setOrdinal] = useState();

  useEffect(()=> {
    setOrdinal(()=> formData.ordinal)
  }, [formData]);

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      let formIndex = Array.prototype.indexOf.call(form, event.target);
      event.preventDefault();
      // Fixes an issue with "kod" field not going into next
      if(isCatalogueForm && formIndex === 0) {
        formIndex = 1
      }
      if (!isCatalogueForm && formIndex === 1) {
        formIndex = 2;
      }
      // If in the last field, go to the save field
      if ( (isCatalogueForm && formIndex === inputs.length-1) || (!isCatalogueForm && formIndex === inputs.length) ) {
        document.getElementById(`save${index}`).focus();
        document.getElementById(`save${index}`).click();
      } else {
        form.elements[formIndex + 1].focus();
      }
    }
  };

  return (
    <form>
      {inputs.map((input) => {
        return (
          <Input
            key={input.label}
            input={input}
            handleChange={handleChange}
            handleEnter={handleEnter}
            autogeneratedCode={autogeneratedCode}
            isCatalogueForm={isCatalogueForm}
            ordinal={ordinal}
          />
        );
      })}
    </form>
  );
};

export default Form;
//CONSTANTS
import { collections } from "../../constants/DataCollections";
//STYLES
import styles from "./RadioButtons_Style";
//HELPERS
import { unsavedChangesDialog } from "../../helper/Alerts";

const RadioButtons = ({ activeCollection, setActiveCollection, modifiedCards, setModifiedCards }) => {
	
	const changeCollection = (e) => {
    unsavedChangesDialog(modifiedCards, setModifiedCards, ()=>setActiveCollection(e.target.value))
  }

	return(
	<div style={styles.wrapper}>
		{collections.map((collection) => {
			return (
				<label
					key={collection}
				> 
					<input 
						type={"radio"} 
						value={collection}
						checked={activeCollection === collection}
						onChange={(e) => changeCollection(e)}
					/>
					{collection}
				</label>
			)
		})}
	</div>
	)
}

export default RadioButtons;
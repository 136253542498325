import React from "react";
import styles from "./Header_Styles";

const Header = ({children}) => {
  return (
    <div style={styles.wrapper}>
      <div style={styles.title}>
        Zec Arhiva
      </div>
      {children}
    </div>
  )
};

export default Header;

import colors from "../../constants/Colors";

const styles = {
  evenItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
    width: "100%",
    paddingBottom:"0.5%",
    backgroundClip: "content-box",
    fontFamily: "Times"
  },
  oddItem: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
    width: "100%",
    paddingBottom:"0.5%",
    backgroundClip: "content-box",
    fontFamily: "Times"
  },
  item: {
    flexGrow: 1,
    flexBasis: "100%",
  },
  img: {
    maxWidth:"35%", 
    maxHeight:"100%",
    height:"100%", 
    backgroundPosition:"center", 
    backgroundRepeat:"no-repeat", 
    backgroundSize:"cover",
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3
  },
  ordinal: {
    fontWeight: 600,
    color: colors.sangria
  }
};

export default styles;

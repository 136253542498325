import colors from "../../../constants/Colors";

const styles = {
  wrapper: {
    fontSize: "0",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    borderTop: "1px solid rgba(0, 0, 0, 0.21)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.21)",
    borderRight: "1px solid rgba(0, 0, 0, 0.21)",
    borderLeft: "1px solid rgba(0, 0, 0, 0.21)",
    marginTop: "2%",
    width: "80%",
    color: "black",
    fontFamily: "Times",
    height:"2em",
    backgroundColor: colors.white,
  },
  inputInMiddle: {
    borderTop: "0",
    marginTop: "0"
  },
  label: {
    fontFamily:"Times",
    fontSize:"1.2rem",
    lineHeight: "1.7em",
    minWidth: "8em"
  },
  codeWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  codeEditable: {
    borderRight: "transparent",
    width: "50%",
    textAlign: "end"
  },
  autogeneratedCode: {
    borderLeft: "transparent",
    width:"100%"
  }
}

export default styles;
const styles = {
  outerWrapper: {
    width:"100%",
    height: "100%"
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "80%",
    padding: "2.518% 4.341%",
  }
};

export default styles;

import { getFirestore, collection, query, orderBy, getDocs, addDoc, updateDoc, deleteDoc, doc } from "@firebase/firestore";
import { initializeFirebaseApp } from "./FirebaseInitialize";
import { deleteImage } from "./ImageApi";
import { openSuccessAlert, openFailedAlert } from "../helper/Alerts";

initializeFirebaseApp();
const db = getFirestore();

export const getCollection = async (collectionName) => {
  const docs = [];
  try {
    const orderedQuery = query(collection(db, collectionName), orderBy("ordinal", "asc"));
    const snapshot = await getDocs(orderedQuery);
    snapshot.forEach((doc) => {
      docs.push(doc.data());
    });
  } catch (error) {
    console.log(error);
  }
  return docs;
};

export const addDocument = async (data, setId, collectionName) => {
  const docRef = await addDoc(collection(db, collectionName), data);
  updateDoc(docRef, {
    id: docRef.id
  })
  .then(() => {
    setId(docRef.id);   
    console.log("Document written with ID: ", docRef.id); 
    openSuccessAlert("dodali");
  })
  .catch((error) => openFailedAlert("Dodavanje"));
}

export const updateDocument = async (data, id, collectionName) => {
  console.log(data, id);
  const docRef = doc(db, collectionName, id); 
  updateDoc(docRef, data)
  .then(()=> openSuccessAlert("izmijenili"))
  .catch((error) => openFailedAlert("Izmjenjivanje"));
}

export const deleteDocument = async (data, id, collectionName) => {
  if(data.imageMini){
    await deleteImage(data.imageMini);
    await deleteImage(data.imageMidi);
    await deleteImage(data.imageMaxi);
  }
  deleteDoc(doc(db, collectionName, id))
  .then(() => openSuccessAlert("obrisali"))
  .catch((error) => openFailedAlert("Brisanje"));
}
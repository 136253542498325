const styles = {
  wrapper: {
    width:"100%", 
    padding: "2% 4.341% 0", 
    marginTop: "50px", 
    display: "flex", 
    flexDirection:"row", 
    justifyContent: "space-between"
  },
  buttons:
  {
   width:"100%",
   display:"flex",
   flexDirection:"row",
   gap:"6%"
  },
  viewSelect: {
    display: "flex",
    width: "5%",
    justifyContent: "space-between"
  },
  img: {
    width: "40%",
    height: "auto"
  }
}

export default styles;
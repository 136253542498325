export const autogeneratedCodeToString = (autogeneratedCode) => {
    return "-" + autogeneratedCode.format + autogeneratedCode.year + "-" + autogeneratedCode.imageName;
}

export const codeToObject = (code) => {
  if(code !== undefined && (code.split("-")).length===3){
    const codeParts = code.split("-");
    return {
      editable: codeParts[0],
      format: codeParts[1].substr(0,4),
      year: codeParts[1].substr(4),
      imageName: codeParts[2]
    }
  }
}
/**
 * @param value - value of input field _format_ from form
 ** Value is split around character _x_ to two parts, and 
 * only is used integer value of numbers. 
 */
export const formatFormat = (value) =>{
    const values = value.split("x"); 
    const valuesInteger = values.map(el =>  ((el.replace(/\,[^/.]+$/, "")).replace(/\.(.*?\d*)/g,"")).trim());
    return( valuesInteger[0].replace(/\D/g,'')+valuesInteger[1].replace(/\D/g,''))
}

export const formatImageName = (value) => {
  const name = value.replace(/\.[^/.]+$/, "").substr(-4);
  if(!(/^\d+$/).test(name)){
    return 0 + name.substr(1);
  }
  return name;
}
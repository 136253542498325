import colors from "../constants/Colors";

const styles = {
  button:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "3px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    fontSize: "1em",
    lineHeight: "1.1em",
  },
  blueButton: {
    background: colors.dodgerBlue,
    border: "none",
    color: colors.white,
  },
  whiteButton: {
    color: colors.dodgerBlue,
    background: "white",
    border: "1px solid #1D85FF"
  }
}

export default styles;
import React from "react";
import styles from "./TableRow_Styles";

const TableRow = ({ index, style, data }) => {

  const handleClick = () => {
    data.onPress();
    data.setIndex(index);
  }
  const imgUrl = data.items[index].imageMaxi;

  return (
    <div style={style} onClick={handleClick}>
      <div style={index % 2 === 0 ? styles.oddItem : styles.evenItem}>
        {/* Small Image of Artwork*/}
        <div style={{...styles.item, ...{height:"100%"}}}>
          {imgUrl && 
            <div 
              style={{
                ...styles.img, 
                ...{backgroundImage:`url("${imgUrl}")`}
              }}/>
          }
        </div> 
        {/* Rest of columns  */}
        <div style={{...styles.item, ...styles.ordinal}}>
          {data.items[index].ordinal}
        </div>
        <div style={styles.item}>{data.items[index].code}</div>
        <div style={styles.item}>{data.items[index].descriptiveTitle}</div>
        <div style={styles.item}>{data.items[index].technique}</div>
        <div style={styles.item}>{data.items[index].format}</div>
        <div style={styles.item}>{data.items[index].year}</div>
        <div style={styles.item}>{data.items[index].condition}</div>
      </div>
    </div>
  );
};

export default TableRow;

import colors from "../../constants/Colors";

const styles = {
  wrapper: { 
    position: "relative",
    padding: 0,
    margin:0
  },
  image: {
    position: "absolute",
    left: "5%",
    top: "20%"
  },
  search: {
    width: "21.007vw",
    height: "3.112vh",
    background: colors.alabaster,
    borderRadius: "3px",
    margin:0,
    paddingLeft:"18%",
    border: "none"
  },
  searchTablet: {
    minHeight: "2em",
  }
};

export default styles;
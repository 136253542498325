import React, { useState } from "react";
import styles from "../../styles/Buttons_Styles";
import { buttonClickHandler } from "../../helper/ButtonClickHandler";

const Button = ({buttonProps}) => {
  const [isActive, setIsActive] = useState(false);

  return(
    <button 
      id={buttonProps.id}
      style={{
        ...styles.button, 
        ...buttonProps.buttonStyle, 
        ...isActive && {opacity:"0.8"}
     }} 
      onClick={()=>buttonClickHandler(setIsActive, buttonProps.onClick)}
    >
      <img 
          src={buttonProps.imageSource} 
          style={buttonProps.imageStyle}
      />
      {buttonProps.text}
    </button>
  )
}

export default Button;
import colors from "../../../constants/Colors";

const borderStyle = "1px solid rgba(0, 0, 0, 0.21)";
const styles = {
  wrapper: {
    fontSize: "0",
    display: "flex",
    justifyContent: "stretch",
  },
  input: {
    borderTop: borderStyle,
    borderBottom: borderStyle,
    borderRight: borderStyle,
    borderLeft: borderStyle,
    marginTop: "2%",
    width: "66%",
    color: "black",
    fontFamily: "Times",
    fontSize: "1.2rem",
    lineHeight: 1.1,
    height: "4%",
    backgroundColor: colors.white,
  },
  ordinal: {
    color: colors.sangria,
    fontWeight: 600,
  },
  inputInMiddle: {
    borderTop: "0",
    marginTop: "0",
  },
  label: {
    fontFamily: "Times",
    borderTop: borderStyle,
    borderBottom: borderStyle,
    borderLeft: borderStyle,
    padding: "0 1%",
    fontSize: "1.2rem",
    lineHeight: 1.1,
    alignItems: "center",
    minWidth: "34%",
    display: "flex",
    justifyContent: "space-between",
  },
  codeWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "66%",
    height: "100%",
  },
  codeEditable: {
    borderRight: "transparent",
    width: "50%",
    textAlign: "end",
    height: "4%",
    marginTop: "3%",
  },
  autogeneratedCode: {
    borderLeft: "transparent",
    width: "50%",
    height: "4%",
    marginTop: "3%",
  },
};
export default styles;

import colors from "../../constants/Colors";

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: colors.white,
    width: "100%",
    height: "50px",
    position: "fixed",
    padding: "1.303% 4.341%",
  },
  title: {
    color: colors.black,
    fontFamily: "Times",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "1.83333em",
  }
};

export default styles;

import backroundImg from "../../assets/images/login_background.jpg";
import colors from "../../constants/Colors";

const styles = {
  loginWrapper: {
    width: "100%",
    height: "100%",
    backgroundImage: "url(" + backroundImg + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    justifyContent: "space-around",
    margin: "auto",
    padding: "15% 0",
    fontSize: "1.5em"
  },
  input: {
    margin: "4% 0",
    textAlign: "center",
    height: "3.2em",
    borderRadius: "2em",
    boxShadow: "0px 4px 10px rgba(158, 144, 115, 0.05)",
    border: "none",
    background: colors.white,
    fontFamily: "Open Sans"
  },
  button: {
    marginTop: "3%",
    height: "3.2em",
    borderRadius: "2em",
    boxShadow: "0px 4px 10px colors.beaver",
    border: "none",
    color: colors.cultured,
    background: colors.eerieBlack,
    fontFamily: "Open Sans",
    fontWeight: 600
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Times",
    fontWeight: 600,
    fontSize: "3.5em",
    lineHeight: "1.5em",
    whiteSpace: "nowrap",
    fontStyle: "italic",
    color: colors.black
  },
  loginError: {
    fontSize: "0.7em",
    color: colors.ueRed,
    display: "flex",
    justifyContent: "center",
    textStyle: "normal",
    lineHeight: "0.9em",
    padding: "1%",
    whiteSpace: "nowrap",
    fontWeight: 500
  },
  inputEmailError: {
    border: "1px solid rgb(192, 4, 4)",
    boxSizing: "border-box",
    background: colors.white,
    boxShadow: "0px 4px 10px rgb(158, 144, 115)"
  }
}

export default styles;
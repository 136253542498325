// styles
import styles from "./UploadImageMessage_Styles";

const UploadImageMessage = ({ uploadedImage }) => {
    
  return (
    <>
      { uploadedImage.url!==null && uploadedImage.error===null && uploadedImage.new === true &&
          <div style={styles.wrapper}>
              Upload uspješan!
          </div>
      }
      { uploadedImage.error!==null && uploadedImage.new === true &&
          <div style={styles.wrapper}>
              Greška pri upload-u!
          </div>
      }
    </>
  )
}

export default UploadImageMessage;
import React, { useState, useEffect } from "react";
import Compress from "compress.js";

//COMPONENTS
import EditView from "../edit-view/EditView";
//HELPER
import { setData } from "../form/FormHelper";
import { uploadImage, getImageURL, getImageBySize } from "../../firebase/ImageApi";
import { addDocument, updateDocument, deleteDocument } from "../../firebase/FirestoreController";
import { openConfirmationDialog } from "./ConfirmationDialog"
import { autogeneratedCodeToString, formatFormat, formatImageName } from "../../helper/CodeTransformations";
//CONSTANTS
import { autogeneratedCodeEmpty, uploadedImageEmpty, formDataEmpty } from "../../constants/EmptyStates";
import Form from "../form/Form";
import Button from "../button/Button";
import { buttonProps } from "../edit-view/Constants";
//STYLES
import style from "./FormController_Style";

const FormController = ({
  isCatalogue,
  activeCollection,
  works,
  isModalOpen,
  setIsOpen,
  index,
  setIndex,
  update,
  modifiedCards,
  setModifiedCards,
  isCardModified,
  setIsCardModified
}) => {
  
  const [formData, setFormData] = useState(formDataEmpty);
  const [uploadedImage, setUploadedImage] = useState(uploadedImageEmpty);
  const [id, setId] = useState();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [autogeneratedCode, setAutogeneratedCode] = useState(autogeneratedCodeEmpty);
  const isCatalogueForm = isCatalogue && (index!==works.length);

  const handleChange = ( event ) => {
    // checking if input field format has two parts when splitted with character "x"
    // formatFormat method is called and result is saved in property format of state autogeneratedCode
    if (
      event.target.name === "format" &&
      event.target.value.split("x").length === 2
    ) {
      setAutogeneratedCode((autogeneratedCode) => ({
        ...autogeneratedCode,
        format: formatFormat(event.target.value),
      }));
    }
    // checking if input field "year" has 4 characters
    // updating state autogeneratedCode as property year with last 2 characters of input
    if (
      event.target.name === "year" &&
      event.target.value.trim().length === 4
    ) {
      setAutogeneratedCode((autogeneratedCode) => ({
        ...autogeneratedCode,
        year: event.target.value.trim().substr(-2),
      }));
    }
    // checking for input field "code", updating property editable of state autogeneratedCode
    if (event.target.name === "code") {
      setAutogeneratedCode((autogeneratedCode) => ({
        ...autogeneratedCode,
        editable: event.target.value.trim(),
      }));
    }
    // checking for input field "tags",
    // method split returns array of substrings separated by character ","
    // updating property tags of state formData with array
    if (event.target.name === "tags") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value.split(","),
      });
    }
    // checking for input field "ordinal"
    // updating property ordinal of state formData with number or string value (depending on input)
    if (event.target.name === "ordinal") {
      Object.is(NaN, parseInt(event.target.value))
        ? setFormData({
            ...formData,
            [event.target.name]: event.target.value,
          })
        : setFormData({
            ...formData,
            [event.target.name]: parseInt(event.target.value),
          });
    }
    else {
      if(event.target.name!=="code") {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      }
    }
    // creating object whose keys are the indexes of each modified work in the _Catalogue View_ 
    // and value is an array of names of modified inputs
    if(isCatalogueForm){
      if(modifiedCards[index] === undefined){
        setModifiedCards((prevState) => ({...prevState, [index]: [event.target.name]}))
      }else if(modifiedCards[index]!==undefined && !(modifiedCards[index]).includes(event.target.name)){
        setModifiedCards((prevState) => ({...prevState, [index]: [...modifiedCards[index], event.target.name]}))
      } 
    }
    setIsFormChanged(true);
  };

  /**
     * @param {Event} event - captures event of user choosing image from their device storage. 
     ** Once chosen, original name of image is saved in variable _nameOfImage_. 
     ** Method **formatImageName** is called and result is saved in property _imageName_ of state _autogeneratedCode_    
     *** Method **uploadImage** is called to upload image in Firebase Storage. This method cannot be used to get
        _downloadURL_ of image, as image is saved with 3 specific dimensions and the original one is deleted immediately.
        Instead, this method returns original image name + "_388x521" (one of the specific dimensions) 
        and returned value is saved in variable _name_.
     ** Value of _name_ is passed in method **getBigImage** that on success returns _downloadURL_ 
        of the biggest image that is uploaded in storage. This value is used to update states: 
        property _url_ of state _uploadedImage_ (so image can be displayed) and 
        property _imageMaxi_ of state _formData_ (to be saved in Firestore). 
     ** Then, value of _name_ is passed into method **getImageBySize** that is called two times with proper image name extensions 
        for two remaining specific dimensions. This method returns _downloadURL_ that is saved in  
        properties _imageMidi_ and _imageMini_ of state _formData_, respectively, to be saved in Firestore. 
     ** If method **getBigImage** returns error it is saved in property _error_ of state _uploadedImage_.   
     */

  const compressImage = (event, width, height) => {
    const compress = new Compress();
    const files = [...event.target.files]
    let file;

    compress.compress(files, {
      size: 4, // the max size in MB, defaults to 2MB
      quality: .75, // the quality of the image, max is 1,
      maxWidth: width, // the max width of the output image, defaults to 1920px
      maxHeight: height, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
      rotate: false, // See the rotation section below

    }).then(async (data) => {
      // returns an array of compressed images
      file = Compress.convertBase64ToFile(data[0].data, data[0].ext);

      await uploadImage(file, data[0].alt, "_" + width.toString() + "-" + height.toString());

      var imageURL = await getImageURL(event.target.files[0].name, "_400-762");

      setUploadedImage((prevState) => ({ ...prevState, url: imageURL }));
      setFormData((prevState) => ({ ...prevState, imageMaxi: imageURL }))
    })
  }

  const uploadButtonAction = async (event) => {
    console.log("event: ", event)
    
    setUploadedImage(uploadedImageEmpty);

    const nameOfImage = event.target.files[0].name;
    setAutogeneratedCode((autogeneratedCode) => ({
      ...autogeneratedCode,
      imageName: formatImageName(nameOfImage),
    }));

    compressImage(event, 400, 762);  
    compressImage(event, 136, 180);
    compressImage(event, 60, 35);
  };


  const saveButtonAction = () => {
    if (index === works.length) {
      addDocument(formData, setId, activeCollection);
    } else if (id !== undefined) {
      updateDocument(formData, id, activeCollection);
    }
    if(!isCatalogueForm){
      setIsFormChanged(false);
      closeEditView();
    } else {
      delete modifiedCards[index];
      setModifiedCards(modifiedCards);
      setIsCardModified(false);
    }
  };
  const deleteButtonAction = () => {
    if (index !== works.length) {
      deleteDocument(works[index], id, activeCollection);
    }
    closeEditView();
  };
  const closeButtonAction = () => {
    openConfirmationDialog(isFormChanged, saveButtonAction, cancelButtonAction);
    closeEditView();
  };
  const cancelButtonAction = () => {
    setIsFormChanged(false);
    setData(
      setFormData,
      works,
      index,
      setUploadedImage,
      setId,
      setAutogeneratedCode
    );
    closeEditView();
  };
  const closeEditView = () => {
    setFormData(formDataEmpty);
    setIndex(null);
    update();
    setIsOpen(false);
  };

  useEffect(() => {
    // openConfirmationDialog(isFormChanged, saveButtonAction, cancelButtonAction);
      setData(
        setFormData,
        works,
        index,
        setUploadedImage,
        setId,
        setAutogeneratedCode,
        isCatalogue
      );  
  }, [index, activeCollection, works]);

  useEffect(() => {
    if(isFormChanged){
      const codeInString =  autogeneratedCode.editable + autogeneratedCodeToString(autogeneratedCode);
      setFormData({
        ...formData,
        code: codeInString
      });
    	} 
  }, [autogeneratedCode]);

  return (
    <>
      {(isCatalogueForm && index!==null ) ? 
        <> 
          <Form
            formData={formData}
            handleChange={handleChange} 
            autogeneratedCode={autogeneratedCode} 
            isCatalogueForm={isCatalogueForm}
            index={index}
          />
          {isCardModified ?
            <div style={style.wrapper}>
              <Button buttonProps={{...buttonProps.save,
                  onClick: saveButtonAction,
                  id: `save${index}`
                }}
              />
            </div> 
            : null
          }
        </>
        : null
      }
      <EditView
        isModalOpen={isModalOpen}
        index={index}
        handleChange={handleChange}
        formData={formData}
        uploadedImage={uploadedImage}
        autogeneratedCode={autogeneratedCode}
        cancelButtonAction={cancelButtonAction}
        saveButtonAction={saveButtonAction}
        uploadButtonAction={uploadButtonAction}
        closeButtonAction={closeButtonAction}
        deleteButtonAction={deleteButtonAction}
        isCatalogueForm={isCatalogueForm}
      /> 
    </>
     
  );
};
export default FormController;
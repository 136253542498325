import React, {useEffect, useState} from "react";
// COMPONENTS
import FormController from "../../form/FormController";
// STYLES
import styles from "./Card_Style";

const Card = ({ index, style, data }) => {
  const isFirstItem = index === 0;
  const [isCardModified, setIsCardModified] = useState(false);
  
  useEffect(()=> {
    if((Object.keys(data.modifiedCards)).includes(index.toString())){
      setIsCardModified(true);
    }
  }, [data.modifiedCards])

  return(
    <div style={style}>
      <div style={{
        ...!isFirstItem && styles.wrapper,
        ...isFirstItem && styles.firstItemWrapper,
        ...isCardModified && styles.modifiedCard
      }}>
        <div style={styles.photoPlaceholder}>
          <img 
            style={styles.photo} 
            src={data.items[index].imageMaxi}
          />
        </div>
        <FormController
          isCatalogue={data.isCatalogue}
          activeCollection={data.activeCollection}
          isModalOpen={false}
          setIsOpen={()=>null}
          works={data.items}
          index={index}
          setIndex={()=>null}
          update={data.update}
          modifiedCards={data.modifiedCards}
          setModifiedCards={data.setModifiedCards}
          isCardModified={isCardModified}
          setIsCardModified={setIsCardModified}
        />
      </div>
    </div>
  )
}

export default Card;
const styles = { 
	wrapper : {
		display: "flex", 
		flexDirection: "row", 
		alignContent: "center", 
		justifyContent: "end", 
		margin: "1% 4.5%"  
	}
}

export default styles;
import { codeToObject } from "../../helper/CodeTransformations";
import { autogeneratedCodeEmpty, uploadedImageEmpty, formDataEmpty } from "../../constants/EmptyStates";
/**
 * 
 * @param {function} setFormData - function which sets form data
 * @param {array} works - array of artist's works
 * @param {number} index - index of current work
 */
export const setData = (setFormData, works, index, setUploadedImage, setId, setAutogeneratedCode, isCatalogue) => {
  if(index===works.length) {
    setFormData({
      ...formDataEmpty,
      ordinal: index+1,
    });
    setUploadedImage(uploadedImageEmpty);
    setAutogeneratedCode(autogeneratedCodeEmpty);
  } else if(((index!==null && index!==works.length) || isCatalogue) && works[index]) { 
      const codeParts = codeToObject(works[index].code);
      if(codeParts){
        setAutogeneratedCode({
          editable: codeParts.editable,
          format: codeParts.format,
          year: codeParts.year,
          imageName: codeParts.imageName
        });
      } else{ 
        setAutogeneratedCode(autogeneratedCodeEmpty);
      }
      setFormData({
        ordinal: works[index] && works[index].ordinal, 
        descriptiveTitle: works[index] && works[index].descriptiveTitle,
        technique: works[index] && works[index].technique,
        format: works[index] && works[index].format,
        totalFormat: works[index] && works[index].totalFormat,
        frame: works[index] && works[index].frame,
        back: works[index] && works[index].back,
        year: works[index] && works[index].year,
        signature: works[index] && works[index].signature,
        flaw: works[index] && works[index].flaw,
        otherFlaws: works[index] && works[index].otherFlaws,
        publication: works[index] && works[index].publication,
        other: works[index] && works[index].other,
        collection: works[index] && works[index].collection,
        material: works[index] && works[index].material,
        photoNumber: works[index] && works[index].photoNumber,
        theme: works[index] && works[index].theme,
        condition: works[index] && works[index].condition,
        tags: works[index] && works[index].tags
      });
      if(works[index].id !== undefined) {
        setId(works[index].id);
      }
      setUploadedImage({
        url: works[index] && works[index].imageMaxi,
        error: null,
        new: false,
      });
    }
}
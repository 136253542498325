// DEPENDENCIES
import React, { useReducer, useState } from "react";
// CONTROLLERS
import { passwordErrorStyle } from "./controllers/Validator";
import { emailErrorStyle } from "./controllers/Validator";
import { loginValidator } from "./controllers/Validator";
import { inputFocusHandler } from "./controllers/Validator";
import { onChangeUserHandler } from "./controllers/OnChangeHandlers";
import { onChangePasswordHandler } from "./controllers/OnChangeHandlers";
import { initialState } from "./controllers/Validator";
import { InputValidator } from "./controllers/Validator";
// STYLES
import styles from "./SignInPage_Style";

const SignInPage = () => {
  // STATES
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");
  const loginTitle = 'Zec Archive';
  const [validator, setValidator] = useReducer(InputValidator, initialState);

const handleEnter = (event, elementId) => {
    if(event.keyCode === 13) {
      event.preventDefault();
      document.getElementById(elementId).focus();
      if(elementId === "signInButton"){
        document.getElementById(elementId).click();
      }
    }
  }

const invalidEmailStyle = emailErrorStyle(validator.invalidEmail, validator.blankEmail, validator.blankPassword);
const invalidPasswordStyle = passwordErrorStyle(validator.invalidPassword, validator.blankPassword, validator.invalidEmail);
  
  return (
   <div style={styles.loginWrapper}> 
    <div style={styles.wrapper}>
      <div style={styles.title}>
        {loginTitle}
        </div>
        <input
          style={{...styles.input, ...invalidEmailStyle}}
          onFocus={() => inputFocusHandler(validator, setValidator)}
          value={user}
          id={"email"}
          placeholder="Email"
          onChange={onChangeUserHandler(validator.invalidEmail, validator.blankEmail, setValidator, setUser)}
          onKeyDown={(event) => handleEnter(event, "password")}
        />
        {validator.invalidEmail && <div style={styles.loginError}> Invalid email address </div>}
        {validator.blankEmail && <div style={styles.loginError}> Please enter correct email </div>}
        <input
          id={"password"}
          style={{...styles.input, ...invalidPasswordStyle}}
          placeholder="Password"
          type="password"
          value={password}
          onFocus={() => inputFocusHandler(validator, setValidator)}
          onChange={onChangePasswordHandler(validator.invalidPassword, validator.blankPassword, setValidator, setPassword)}
          onKeyDown={(event) => handleEnter(event, "signInButton")}
        />
        {validator.invalidPassword && <div style={styles.loginError}> Invalid password </div>}
        {validator.blankPassword && <div style={styles.loginError}> Please enter correct password </div>}
          <button 
            id={"signInButton"}
            style={styles.button}
            onClick={() => loginValidator(user, password, setValidator)}
          > 
            LOG IN
          </button>
          {validator.limitUserAttempts && 
          <div style={{...styles.loginError, fontSize:"0.9em",
                  textStyle:"normal", paddingTop:"0.8em" }}
                >
          Too many attempts, please try again later
          </div>
            }
      </div>
    </div> 
  )
}

export default SignInPage;
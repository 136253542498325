//ASSETS
import cardViewBlue from "../../assets/icons/cardViewBlue.svg";
import cardViewGrey from "../../assets/icons/cardViewGrey.svg";
import tableViewBlue from "../../assets/icons/tableViewBlue.svg";
import tableViewGrey from "../../assets/icons/tableViewGrey.svg";
//COMPONENTS
import Button from "../button/Button";
//STYLES
import styles from "./Dashboard_Styles";
//HELPERS
import { unsavedChangesDialog } from "../../helper/Alerts";

const Dashboard = ({ buttonProps,buttonPropsPdf,view, setView, modifiedCards, setModifiedCards }) => {
  const cardViewIcon = view === "catalogue" ? cardViewBlue : cardViewGrey;
  const tableViewIcon = view === "table" ? tableViewBlue : tableViewGrey;

  return(
    <div style={styles.wrapper}>
      <div style={styles.buttons}>
        <Button buttonProps={buttonProps}/>
        {view === "catalogue"&&<Button buttonProps={buttonPropsPdf} />}
      </div>
      <div style={styles.viewSelect}>
        <img 
          style={styles.img}
          src={cardViewIcon} 
          onClick={()=>setView("catalogue")} 
          alt={"Katalog"}
        />
        <img 
          style={styles.img}
          src={tableViewIcon} 
          onClick={()=>unsavedChangesDialog(modifiedCards, setModifiedCards, ()=>setView("table"))}
          alt={"Tabela"}
        />
      </div>
    </div>
  )
}

export default Dashboard;
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { initializeFirebaseApp } from "../../firebase/FirebaseInitialize";

initializeFirebaseApp();
export const auth = getAuth();

/**
 * @param {*} email @param {*} password are passed into Firebase method **signInWithEmailAndPassword**
 * to authenticate user 
 */
export const signIn = async (email, password, setValidator) => {
  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    const user = userCredential.user;
      if(user.email) {
        setValidator({type: "invalidEmail", value: false})
        setValidator({type: "invalidPassword", value: false})
      }
   })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error("errorCode: ", errorCode);
    console.error("errorMessage: ", errorMessage);

    if(error.code === 'auth/internal-error') {
      setValidator({type: "invalidPassword", value: true})
    }

    if(error.code === 'auth/user-not-found') {
      setValidator({type: "invalidEmail", value: true})
    }

    // SDK auth error - max limit is 20QPS/I.P address (QPS means 'query per second')
    if(error.code === 'auth/too-many-requests') {
      setValidator({type: "limitUserAttempts", value: true})
    }

    if(error.code === 'auth/invalid-email') {
      setValidator({type: "invalidEmail", value: true})
    }
    
    if(error.code === 'auth/wrong-password') {
      setValidator({type: "invalidPassword", value: true})
    }
  });
}
const styles = {
  outerWrapper: {
    width: "100%", 
    margin: "auto",
  },
  arrowBackward: {
    position:"absolute", 
    top:"50%", 
    left: "2%",
    width: "1%"
  },
  arrowForward: {
    position:"absolute", 
    top:"50%", 
    right: "2%",
    width: "1%"
  },
  paddingBottom:
  {
    paddingBottom:"5%"
  },
  list: {
    width: "100%", 
    margin: "auto",
    padding: "0 4.341%"
  },
  fallbackMessage: {
    padding: "2% 4.341%"
  }
}

export default styles;
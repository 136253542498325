import colors from "../constants/Colors";

const styles = {
  wrapper: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.alabaster,
  },
  button: {
    width: "10.362%",
    height: "100%",
    padding:"0.5%"
  }
};

export default styles;

import React, { useContext, useEffect } from "react";
import { auth } from "./firebase/FirebaseAuth";
import { AuthenticatedUserContext } from "./AuthenticatedUserProvider";
// Pages to be displayed
import SignInPage from "./sign-in-page/SignInPage";
import HomePage from "../pages/HomePage";

const AuthenticationCheck = () => {
    const {user, setUser} = useContext(AuthenticatedUserContext);
   
    useEffect(() => {
      const unsubscribeAuth = auth.onAuthStateChanged(
          (authenticatedUser) => {
              authenticatedUser ? setUser(authenticatedUser) : setUser(null);
        }
      );  
      return unsubscribeAuth;
    }, []);
    
    if(user!==null){ 
      return <HomePage/>
    }
    else {
      return <SignInPage/>
    }
  }

  export default AuthenticationCheck;
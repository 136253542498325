//DEPENDENCIES
import React, { useRef, useState, useEffect } from "react";
import { FixedSizeList as List } from "react-window";
//COMPONENTS
import Card from "./card/Card";
import NavigatingButton from "./navigating-button/NavigatingButton";
//STYLES
import styles from "./Catalogue_Style";
import "../../constants/styles/print.css";
//HELPERS
import { unsavedChangesDialog } from "../../helper/Alerts";
import { findScrollCounterEndValue } from "../../helper/ListNavigation";
import { scrollCounterEmpty } from "../../constants/EmptyStates";

const Catalogue = ({ works, activeCollection, update, isCatalogue, modifiedCards, setModifiedCards }) => {
  const isWorksEmpty = works.length === 0
  const itemHeight = window.innerHeight * 0.90 - 50;
  const listWidth = window.innerWidth * 0.91318;
  const [itemWidth, setItemWidth] = useState((window.innerWidth * 0.91318)/3);
  const listRef = useRef();
  const [scrollCounter, setScrollCounter] = useState(scrollCounterEmpty);

  //changes number of visible cards when orientation is changed from portrait to landscape and vice versa
  //if orientation is portrait, 2 cards are visible
  //if orientation is landscape, 3 cards are visible
  useEffect(() => {
    setScrollCounter(scrollCounterEmpty);
    if(listRef.current!==undefined){
      findScrollCounterEndValue(works.length, setScrollCounter);
      listRef.current.scrollTo(0);
    }
    if(window.innerHeight > window.innerWidth){
      setItemWidth((window.innerWidth * 0.91318)/2);
    }
    window.addEventListener('resize', handleResize);   
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [activeCollection, works.length]);
  
  const scrollForward = () => {
    if(scrollCounter.current!==scrollCounter.end){
      listRef.current.scrollToItem(scrollCounter.current+5);
      setScrollCounter((prevValue) => ({...prevValue, current: scrollCounter.current+3}));
      update();
    }
  };
  const scrollBackward = () => {
    if(scrollCounter.current!==0){
      listRef.current.scrollToItem(scrollCounter.current-3);
      setScrollCounter((prevValue) => ({...prevValue, current: scrollCounter.current-3}));
      update();
    }
  };

  const handleResize = () => {
    if(window.innerHeight > window.innerWidth){
      setItemWidth((window.innerWidth * 0.91318)/2);
    } else {
      setItemWidth((window.innerWidth * 0.91318)/3);
    }
  }

  return(
    <div>
      { isWorksEmpty &&
        <div style={styles.fallbackMessage}>Nema Podataka</div>
      }
      { works.length !== 0 &&
        <div style={styles.outerWrapper}>
          <div className="noPrint">
          <NavigatingButton
            direction="backward"
            onClick={()=> unsavedChangesDialog(modifiedCards, setModifiedCards, scrollBackward)}
          />
          </div>
          <div style={styles.list}>
            <List
              height={itemHeight}
              itemSize={itemWidth}
              width={listWidth}
              itemCount={works.length}
              itemData={{
                items: works, 
                activeCollection: activeCollection, 
                update: update, 
                isCatalogue: isCatalogue,
                modifiedCards: modifiedCards,
                setModifiedCards: setModifiedCards
              }}
              direction="horizontal"
              ref={listRef}
            >
              {Card}
            </List>
          </div>
          <div className="noPrint">
          <NavigatingButton 
            direction="forward"
            onClick={()=> unsavedChangesDialog(modifiedCards, setModifiedCards, scrollForward)}
          />
          </div>
        </div>
      }
    </div>
  )
}

export default Catalogue;

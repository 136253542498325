const colors = {
  white: "#FFFFFF",
  black: "#000000",
  gray: "#888888",
  alabaster: "#F8F8F8",
  dodgerBlue: "#1D85FF",
  mineShaft: "#323232",
  emperor: "#555555",
  silver:  "#C9C9C9",
  sangria: "#970303",
  eerieBlack: "#241C1C",
  cultured: "#F5F4F2",
  ueRed: "#C00404",
  beaver: "#9E9073"
}

export default colors;


/**
 * A function that changes the state relative to the value entered in the email field
 * 
 * @param {boolean} invalidEmail - indicates that the entered email is incorrect, initial value is false
 * @param {boolean} blankEmail - indicates that the email field is empty, initial value is false
 * @param {function} setValidator - receive the previous value and return an updated value for useReducer state
 * @param {function} setUser - receive the previous value and return an updated value for user state
 * @param {object} event - when user types triggers event
 *   
 */ 
export const onChangeUserHandler = (invalidEmail, blankEmail, setValidator, setUser) => (event) => {
  if(invalidEmail) {
    setValidator({type: "invalidEmail", value: false})
  }
  if(blankEmail) {
    setValidator({type: "blankEmail", value: false})
  }
  setUser(event.target.value);
}

/** 
 * A function that changes the state relative to the value entered in the password field
 * 
 * @param {boolean} invalidPassword - indicates that the entered password is incorrect, initial value is false
 * @param {boolean} blankPassword - indicates that the password field is empty, initial value is false
 * @param {function} setValidator - receive the previous value and return an updated value for useReducer state
 * @param {function} setPassword - receive the previous value from password and return an updated value for password state  
 * @param {object} event - when user types triggers event
 * 
 */ 
export const onChangePasswordHandler = (invalidPassword, blankPassword, setValidator, setPassword) => (event) => {
  if(invalidPassword) {
    setValidator({type: "invalidPassword", value: false})
  }
  if(blankPassword) {
    setValidator({type: "blankPassword", value: false})
  }
  setPassword(event.target.value);
}
import React, { useState, useEffect } from "react";
import styles from "./SearchBar_Styles";
import { isTablet } from "../../helper/TabletCheck";

const SearchBar = ({works, setWorks, update}) => {

  const [search, setSearch] = useState("");

  const isKeyboardEnterPressed = (event) => event.keyCode === 13 ? true : false

  const searchAction = (event) => {
    if(isKeyboardEnterPressed(event)){
      if(search === ""){
        update();
    }
      const filteredWorks = works.filter((work) => {
        const keysToSearch = Object.keys(work).filter(key=> key!=="id" && key !=="imageMini" && key!=="imageMaxi" && key!=="imageMidi");
        return keysToSearch.some(keys => {
          return (String(work[keys]).toLowerCase()).match(search.toLowerCase());
        });
      });
      if(filteredWorks.length !== 0){
        setWorks(filteredWorks);
      } else {
        setWorks([]);
      }
    }
  }

  useEffect(()=>{
    update();
  }, [search])

  return(
    <div style={styles.wrapper}>
      <img style={styles.image} src={require("../../assets/icons/search.svg").default}/>
      <input
        style={{
          ...styles.search, 
          ...(isTablet && styles.searchTablet)
        }}
        type="text"
        value={search}
        onChange={(event)=> setSearch(event.target.value)}
        onKeyDown={searchAction}
      />
    </div>
  )
}

export default SearchBar;
//DEPENDENCIES
import React from "react";
import { FixedSizeList as List } from "react-window";
//COMPONENTS
import Labels from "../labels/Labels";
import TableRow from "../table-row/TableRow";
//STYLES
import styles from "./TableView_Styles";

const TableView = ({ 
  works, 
  setActiveIndex, 
  setIsOpenEditView 
}) => {
  let isWorksEmpty = works.length === 0
  
  return (
    <div style={styles.outerWrapper}>
      <div style={styles.wrapper}>
        <Labels/>
        { works.length !== 0 &&
          <List
            className={"firefox-scrollbar"}
            height={window.innerHeight}
            itemCount={works.length}
            itemSize={40}
            width={"100%"}
            itemData={{items:works, setIndex:setActiveIndex, onPress:()=>setIsOpenEditView(true) }}
          >
            {TableRow}
          </List>
        }
        { isWorksEmpty &&
        <div>Nema Podataka</div>
        }
      </div>
    </div>
  );
};

export default TableView;


//HELPERS
import { autogeneratedCodeToString } from "../../helper/CodeTransformations";
//STYLES
import cardInputStyles from "./styles/CardInput_Style";
import editViewInputStyles from "./styles/EditViewInputs_Style";

const Input = ({ input, handleChange, handleEnter, autogeneratedCode, isCatalogueForm, ordinal }) => {
  let first = input.first;
  let styles = isCatalogueForm ? cardInputStyles : editViewInputStyles;
  
  const isAutogeneratedCodeField = (inputName) => {
    return inputName === "code" ? true : false;
  };
  const isOrdinalField = (inputName) => {
    return inputName === "ordinal" ? true : false;
  };

  return (
    (isCatalogueForm && isOrdinalField(input.name)) ?
       null
       : 
      <div style={styles.wrapper}>
      <span style={{ ...styles.label, ...(first && { marginTop: "2%" }),    ...!first && styles.inputInMiddle }}>
        {input.label}
        {isCatalogueForm && isAutogeneratedCodeField(input.name) &&
          <span style={styles.ordinal}>
              {ordinal}
          </span>
        }
      </span>
      {/* "Code" is auto generated unique identfier built based on other fields */}
      {isAutogeneratedCodeField(input.name) && (
        <div style={styles.codeWrapper}>
          <input
            style={{
              ...styles.input,
              ...styles.codeEditable,
              ...(!first && styles.inputInMiddle),
            }}
            type="text"
            autoComplete="off"
            value={input.value}
            name={input.name}
            onChange={handleChange}
            onKeyDown={handleEnter}
          />
          <input
            style={{
              ...styles.input,
              ...styles.autogeneratedCode,
              ...(!first && styles.inputInMiddle),
            }}
            type="text"
            autoComplete="off"
            value={autogeneratedCodeToString(autogeneratedCode)}
            disabled={true}
            name={input.name}
            onChange={handleChange}
            onKeyDown={handleEnter}
          />
        </div>
      )}
      {!isAutogeneratedCodeField(input.name) && (
        <input
          style={{ ...styles.input, ...(!first && styles.inputInMiddle) }}
          type="text"
          autoComplete="off"
          value={input.value}
          name={input.name}
          onChange={handleChange}
          onKeyDown={handleEnter}
        />
      )}
    </div>
  );
};

export default Input;
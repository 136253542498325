import { initializeApp, getApps } from "firebase/app";

export const initializeFirebaseApp = () => {
  if(getApps().length === 0) {
    initializeApp({
      apiKey: "AIzaSyBoFPcWEsk_IkSi9I-qA8EVVxEbZ5Yougs",
      authDomain: "zec-archive.firebaseapp.com",
      projectId: "zec-archive",
      storageBucket: "zec-archive.appspot.com",
      appId: "1:1023100349809:web:b24ef47bda379e37879ad0",
      measurementId: "G-77WYKL6KTM",
    });
  }
};

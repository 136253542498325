import colors from "../../constants/Colors"

const styles = {
  wrapper:
  {
    overlay: {
      backgroundColor: "transparent",
      width:"64%",
      margin:"50px 0 0 36%"
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      height: "100%",
      background: "white",
      outline: "none",
      padding: "0%",
      inset: "0",
      borderRadius: "0",
      border: "none",
      boxShadow: "0px 7px 14px 1px rgba(0, 0, 0, 0.15)",
    },
    
  },
  actionSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: "1px solid " + colors.silver,
    height: "12%",
    padding:"2% 3%"
  },
  actionSectionTablet: {
    minHeight: "7em",
  },
  modalTitle: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
  },
  info: {
      color: colors.mineShaft,
      fontWeight: "600",
      fontSize: "1.5em"
  },
  actionButtons: {
      display: "flex",
      flexDirection: "row"
  },
  inputs: {
      display: "flex",
      flexDirection: "column",
      width: "30%",
  },
  photoEdit: {
      width:"40%",
      display: "flex",
      flexDirection: "column",
      marginLeft: "10%"
  },
  photoPlaceholder: {
    margin: "2% 0",
  },
  noPhoto: {
    backgroundColor: colors.alabaster,
    width: "100%",
    height:"60%",
  },
  button:{
    height:"1.8em",
  },
  deleteBtn: {
    color: colors.emperor,
    background: "white",
    border: "none"
  },
  uploadBtn:{
    color: colors.dodgerBlue,
    background: "white",
    border: "none"
  },
  closeBtn: {
    color: colors.dodgerBlue,
    background: "white",
    border: "none",
    fontWeight: "400",
    alignSelf: "flex-start",
    padding: "0"
  },
  content: {
    display:"flex", 
    flexDirection:"row", 
    height:"88%",
    width:"97%",
    justifyContent: "space-between",
    padding: "2% 3%",
    marginRight: "3%",
    overflowY: "scroll"
  },
  actionSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderBottom: "1px solid " + colors.silver,
    height: "12%",
    padding:"2% 3%"
  },
  modalTitle: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  info: {
    color: colors.mineShaft,
    fontWeight: "600",
    fontSize: "1.5em"
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row"
  },
  inputs: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  photoEdit: {
    width:"40%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "10%"
  },
  photo: {
    maxWidth:"100%",
    maxHeight:"100%",
    width: "auto",
    height: "auto"
  }
}

export default styles;
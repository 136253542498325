import React from "react";
import styles from "./Labels_Styles";

const Labels = () => {
  const labels = ["Djelo", "Redni Broj", "Kod" , "Naslov", "Tehnika", "Format", "Godina", "Stanje"];
  
  return (
    <div style={styles.wrapper}>
      {labels.map((label, index) =>{
        return (
          <div
            key={index}
            style={styles.element}
          >
            {label} 
          </div>
        )
      })}
    </div>
  )
}

export default Labels;
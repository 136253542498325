
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, uploadBytes } from "firebase/storage";
import {initializeFirebaseApp} from "./FirebaseInitialize";

initializeFirebaseApp();
const storage = getStorage();

export const uploadImage = async (file, name, size) => { 

  const metadata = {
  contentType: 'image/jpeg'
  };

  const storageRef = ref(storage, "images/resizedImages/" + name + size);
  console.log("StorageREF: ", storageRef)
  await uploadBytesResumable(storageRef, file, metadata);
}

export const getImageURL = async (name, size) => {

  const storageRef = ref(storage, "images/resizedImages/" + name + size);
  let downloadUrl = Object;

  downloadUrl =await getDownloadURL(storageRef);

  return downloadUrl
}

export const deleteImage = async (url) => {
  const storageRef = ref(storage, url);
  deleteObject(storageRef)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      console.log(error);
    });
};

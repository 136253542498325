//DEPENDENCIES
import React, { useState, useEffect } from "react";
//STYLES
import styles from "./HomePage_Styles";
import buttonsStyles from "../styles/Buttons_Styles";
import "../constants/styles/print.css";
//COMPONENTS
import Header from "../components/header/Header";
import TableView from "../components/table-view/TableView";
import Catalogue from "../components/catalogue/Catalogue";
import Dashboard from "../components/dashboard/Dashboard";
import SearchBar from "../components/search-bar/SearchBar";
import FormController from "../components/form/FormController";
import RadioButtons from "../components/radio-buttons/RadioButtons";
//HELPERS
import { getCollection } from "../firebase/FirestoreController";
import { isTablet } from "../helper/TabletCheck";
import { unsavedChangesDialog } from "../helper/Alerts";
//CONSTANTS
import { collections } from "../constants/DataCollections";

const HomePage = () => {
  const [works, setWorks] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOpenEditView, setIsOpenEditView] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeCollection, setActiveCollection] = useState(collections[0]);
  const [view, setView] = useState("table");
  const [modifiedCards, setModifiedCards] = useState({});
  const isCatalogue = view === "catalogue";

  const buttonProps = {
    imageSource: require("../assets/icons/add.svg").default,
    text: "Dodaj Djela",
    buttonStyle: {
      ...styles.button,
      ...buttonsStyles.blueButton,
      ...(isTablet && { minHeight: "2.5em" }),
    },
    onClick: () =>
      unsavedChangesDialog(modifiedCards, setModifiedCards, () => {
        setActiveIndex(works.length);
        setIsOpenEditView(true);
      }),
  };

  const PrintContent = () => {
    document.body.style.zoom = "50%";
    // document.body.style.transform = "scale(0.6)";
    document.body.style.transform = "scale(1.0)";
    window.print();
    document.body.style.transform = "scale(1.0)";
    document.body.style.zoom = "100%";
  };

  const buttonPropsPdf = {
    imageSource: require("../assets/icons/download.svg").default,
    text: "Preuzmi PDF",
    buttonStyle: {
      ...styles.button,
      ...buttonsStyles.blueButton,
      ...(isTablet && { minHeight: "2.5em" }),
    },
    onClick: () => PrintContent(),
  };

  useEffect(() => {
    const fetchWorks = async () => {
      try {
        const response = await getCollection(activeCollection);
        setWorks(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchWorks();
  }, [isUpdated, activeCollection]);

  return (
    <div style={styles.wrapper}>
      <div className="noPrint">
        <Header>
          <SearchBar
            works={works}
            setWorks={setWorks}
            update={() => setIsUpdated(!isUpdated)}
          />
        </Header>
      </div>
      <div className="noPrint">
        <Dashboard
          view={view}
          setView={setView}
          buttonPropsPdf={buttonPropsPdf}
          buttonProps={buttonProps}
          modifiedCards={modifiedCards}
          setModifiedCards={setModifiedCards}
        />
      </div>
      <div className="noPrint">
        <RadioButtons
          activeCollection={activeCollection}
          setActiveCollection={setActiveCollection}
          modifiedCards={modifiedCards}
          setModifiedCards={setModifiedCards}
        />
      </div>
      <div className="noPrint">
        <FormController
          isCatalogue={isCatalogue}
          activeCollection={activeCollection}
          isModalOpen={isOpenEditView}
          setIsOpen={setIsOpenEditView}
          works={works}
          index={activeIndex}
          setIndex={setActiveIndex}
          update={() => setIsUpdated(!isUpdated)}
          modifiedCards={null}
          setModifiedCards={() => null}
          isCardModified={null}
          setIsCardModified={() => null}
        />
      </div>
      {isCatalogue ?(
        <Catalogue
          isCatalogue={isCatalogue}
          works={works}
          activeCollection={activeCollection}
          update={() => setIsUpdated(!isUpdated)}
          modifiedCards={modifiedCards}
          setModifiedCards={setModifiedCards}
        />
        
      ): (
        <TableView
          works={works}
          setIsOpenEditView={setIsOpenEditView}
          setActiveIndex={setActiveIndex}
        />
      )}
    </div>
  );
};

export default HomePage;

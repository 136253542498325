import "./App.css";
import "./styles/Scrollbar.css";
import AuthenticatedUserProvider from "./auth/AuthenticatedUserProvider";
import AuthenticationCheck from "./auth/AuthenticationCheck";

function App() {
  return ( 
    <AuthenticatedUserProvider>
        <AuthenticationCheck/>
    </AuthenticatedUserProvider>
  )
}

export default App;

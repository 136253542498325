import React from "react";
import { buttonProps } from "./Constants";
//STYLES
import styles from "./EditView_Style";
//COMPONENTS
import Form from "../form/Form";
import Modal from "react-modal";
import Button from "../button/Button";
import UploadImageMessage from "../upload-image-message/UploadImageMessage";
//HELPERS
import { isTablet } from "../../helper/TabletCheck";

Modal.setAppElement('#root');

const EditView = ({
  isModalOpen,
  index,
  handleChange,
  formData,
  uploadedImage,
  autogeneratedCode,
  uploadButtonAction,
  cancelButtonAction,
  closeButtonAction,
  saveButtonAction,
  deleteButtonAction,
  isCatalogueForm,
}) => {
  const isImageUrl = (uploadedImage.url !== null && uploadedImage.url !== undefined) && uploadedImage.error === null;

  return(
    <Modal
      style={{overlay: styles.wrapper.overlay, content: styles.wrapper.content}}
      isOpen={isModalOpen}
    >
      <div style={{
          ...styles.actionSection, 
          ...isTablet && styles.actionSectionTablet
        }}
      >
        <div style={styles.modalTitle}>
          <Button
            buttonProps={{...buttonProps.close, onClick: closeButtonAction}}
          />
          <span style={styles.info}>
            Informacije o djelu
          </span>
        </div>
        <div style={styles.actionButtons}>
          <Button buttonProps={{...buttonProps.delete, onClick: deleteButtonAction}} />
          <Button buttonProps={buttonProps.upload} />
          <input
            type={"file"}
            id={"chooseFile"}
            accept={"image/*"}
            style={{display: "none"}}
            onChange={uploadButtonAction}
          />
          <Button buttonProps={{...buttonProps.save,
            onClick: saveButtonAction,
            id: `save${index}`
            }}
          />
          <Button buttonProps={{
            ...buttonProps.cancel,
            onClick: cancelButtonAction}}
          />
        </div>
      </div>
      <div style={styles.content}>
        <div style={{width:"50%"}}>
          <Form
            formData={formData}
            handleChange={handleChange}
            autogeneratedCode={autogeneratedCode}
            isCatalogueForm={isCatalogueForm}
            index={index}
          />
        </div>
        <div style={styles.photoEdit}>
          <div style={{...styles.photoPlaceholder, ...!isImageUrl && styles.noPhoto}}>
            { isImageUrl &&
              <img src={uploadedImage.url} style={styles.photo}/>
            }
          </div>
          <UploadImageMessage uploadedImage={uploadedImage}/>
          <Button
            buttonProps={buttonProps.changePhoto}
          />
        </div>
      </div>
    </Modal>
  )
}
export default EditView;
import styles from "./EditView_Style";
import buttonsStyles from "../../styles/Buttons_Styles";
import upload from "../../assets/icons/upload.svg";
import dlt from "../../assets/icons/delete.svg";
import close from "../../assets/icons/close.svg";

export const buttonProps = {
  close: {
    text: "Zatvori",
    buttonStyle: {...styles.button, ...styles.closeBtn}, 
    imageSource: close,
    imageStyle: {marginRight: "9px"},
  },
  changePhoto: {
    text: "Promijeni Fotku",
    buttonStyle: {...styles.button, ...buttonsStyles.whiteButton, ...{ alignSelf: "flex-end" }}, 
    onClick: () => document.getElementById("chooseFile").click()
  },
  delete:{
    text: "Obriši",
    buttonStyle: {...styles.button, ...styles.deleteBtn},
    imageSource: dlt
  },
  upload:{
    text: "Upload",
    buttonStyle: {...styles.button, ...styles.uploadBtn},
    imageSource: upload,
    onClick: () => document.getElementById("chooseFile").click()
  },
  save: {
    text: "Sačuvaj",
    buttonStyle: {...styles.button, ...buttonsStyles.blueButton, ...{ marginRight:"5px" }}
  },
  cancel: {
    text: "Odustani",
    buttonStyle: {...styles.button, ...buttonsStyles.whiteButton},
  }
}
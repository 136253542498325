/**
 * for changing style or any other prop of a component when it is clicked
 * @param {function} setIsActive - function which sets button's active state to true or false
 * @param {function} onClick - function to be executed when button is clicked
 */
export const buttonClickHandler = (setIsActive, onClick) => {
  setIsActive(true);
  onClick();
  setTimeout(() => {
    setIsActive(false);
  }, 500);
}
export const autogeneratedCodeEmpty = {
  editable: "",
  format: "xxxx",
  year: "xx",
  imageName: "xxxx",
}

export const uploadedImageEmpty = {
  url: null,
  error: null,
  new: true,
}

export const formDataEmpty = {
  ordinal: "",
  descriptiveTitle: "",
  code: "",
  technique: "",
  collection: "",
  material: "",
  format: "",
  totalFormat: "",
  frame: "",
  back: "",
  year: "",
  signature: "",
  flaw: "",
  otherFlaws: "",
  photoNumber: "",
  publication: "",
  other: "",
  theme: "",
  condition: "",
  tags: ""
}

export const scrollCounterEmpty = {
  current: 0, 
  end: 0
}
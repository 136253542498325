// HELPERS
import { signIn } from "../../firebase/FirebaseAuth";
// STYLES
import styles from "../SignInPage_Style";

export const initialState = {invalidEmail: false, invalidPassword: false, limitUserAttempts: false,
                             blankEmail: false, blankPassword: false };

export const InputValidator = (state, action) => {
  switch (action.type) {
    // case where the User enters incorrectly email
    case 'invalidEmail':
      return {...state, invalidEmail: action.value};
    // case where the User enters incorrectly password
    case 'invalidPassword':
      return {...state, invalidPassword: action.value};
    // case where the User enters exceeds the maximum number of attempts
    case 'limitUserAttempts':
      return {...state, limitUserAttempts: action.value};
    // case in which the User tries to login without entering any character in the field for email
    case 'blankEmail':
      return {...state, blankEmail: action.value};
    // case in which the User tries to login without entering any character in the field for password
    case 'blankPassword':
      return {...state, blankPassword: action.value};
    // case in which User entered either wrong password or password without any character
    case 'passwordErrorsState':
      return {...state, invalidPassword: action.value, blankPassword: action.value};
    // case in which User entered either wrong email or email without any character
    case 'emailErrorsState':
      return {...state, invalidEmail: action.value, blankEmail: action.value};
    // case in which User enters the wrong email and the wrong password
    case 'invalidError':
      return {...state, invalidEmail: action.value, invalidPassword: action.value};
    // case in which the User tries to login without entering any character in the fields for password and email
    case 'blankError':
      return {...state, blankEmail: action.value, blankPassword: action.value};
    default:
      throw new Error();
  }
}

export const emailErrorStyle = (invalidEmail, blankEmail, blankPassword) => {
  if (invalidEmail || blankEmail || blankPassword) {
    return styles.inputEmailError;
  } 
}

export const passwordErrorStyle = (invalidPassword, blankPassword, invalidEmail) => {
  if (invalidPassword || blankPassword || invalidEmail) {
    return styles.inputEmailError;
  } 
}

/** 
* Checks if the input fields for user and password are empty, if it's true printing a message
* 
* @param {function} loginValidator - a function that checks that the user input is correct
* @param {function} signIn - a function that we return to FirebaseAuth with a check on the email and password fields
* @param {string} user - indicates the email input field
* @param {string} password - indicates the password input field
* @param {function} setValidator - receive the previous value and return an updated value for useReducer state
*/
export const loginValidator = (user, password, setValidator) => {
  if (user.length > 0 && password.length > 0) {
      signIn(user, password, setValidator)
  } else {
    if (user.length === 0) {
      setValidator({type: "blankEmail", value:true})
    } 
    if (password.length === 0) {
      setValidator({type: "blankPassword", value:true})
    }
  }
}

/** 
* A function that returns the default style after an error on the input by clicking on the same field
* 
* @param {object} validator - default state of the setValidator function
* @param {function} setValidator - receive the previous value and return an updated value for useReducer state
*/
export const inputFocusHandler = (validator, setValidator) => {
  if(validator.invalidEmail || validator.invalidPassword) {
    setValidator({type: "invalidError", value: false})
  }

  if(validator.blankEmail || validator.blankPassword) {
    setValidator({type: "blankError", value: false})
  }  
}














 
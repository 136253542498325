import React, { useState } from "react";
//STYLE
import styles from "../Catalogue_Style";
//HELPER
import { buttonClickHandler } from "../../../helper/ButtonClickHandler";
//ICONS
import backward from "../../../assets/icons/backward.svg";
import forward from "../../../assets/icons/forward.svg";

const NavigatingButton = ({onClick, direction}) => {
  const [isActive, setIsActive] = useState(false);
  const isForward = direction === "forward";

  return (
    <span
      style={{
        ...isForward ? styles.arrowForward : styles.arrowBackward,
        ...isActive && {opacity: "0.5"}
      }}
      onClick={()=>buttonClickHandler(setIsActive, onClick)}
    >
      <img 
        style={{width:"100%"}}
        src={isForward? forward : backward}
        alt={"navigate"}
      />
    </span>
  )
}

export default NavigatingButton;
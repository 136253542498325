import colors from "../../constants/Colors";

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "99.7%",
    paddingBottom: "1.141%",
  },
  element:{
  flexGrow: 1,
  flexBasis: "100%",
  fontSize: "0.91667em",
  color: colors.gray
  }
}

export default styles;